export default {
	dead: '',
	kill: '',
	number: 10,
	// token: !!localStorage.getItem('token'),
	token: '',
	
	refreshToken: '',
	// exp: '',
	// exp2: '',
	role: [],
	gamebo: '',
	cry: '',
	// username: ''
}