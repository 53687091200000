// export const exp = (state) => {
// 	return state.exp
// }
// export const exp2 = (state) => {
// 	return state.exp2
// }
export const kill = (state) => {
	return state.kill
}
export const dead = (state) => {
	return state.dead
}
export const token = (state) => {
	return state.token
}
export const gamebo = (state) => {
	return state.gamebo
}
 export const cry = (state) => {
	return state.cry
}
export const refreshToken = (state) => {
	return state.refreshToken
}
export const role = (state) => {
	return state.role
} 
// export const username = (state) => {
// 	return state.username 
// }